<template>
  <div >
    <v-container fluid class="pt-0 px-0">
      <v-img src="../assets/Teilnehmer_innen.jpg" style="margin:auto" max-height="400px">
        <v-container class="white--text fill-height">
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <ColorCircle color="white" />
            </v-col>
            <v-col cols="12" md="6">
              <h1>{{exhibitor.headline}}</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-container>
    <v-container class="white--text">
      <v-row>
        <v-col cols="6">
          <ColorCircle color="yellow" />
          <br style="clear:both" />
          <div v-html="exhibitor.intro" />
          <div v-if="exhibitor.links.facebook || exhibitor.links.twitter || exhibitor.links.youtube || exhibitor.links.instagram || exhibitor.links.website" class="mt-6">
            <h3>Social Media Kanäle: {{exhibitor.name}}</h3>
            <v-btn v-if="exhibitor.links.facebook" class="mx-1" color="white" text :href="exhibitor.links.facebook" target="_blank">
              <v-icon dark large>mdi-facebook</v-icon>
            </v-btn>
            <v-btn v-if="exhibitor.links.twitter" class="mx-1" color="white" text :href="exhibitor.links.twitter" target="_blank">
              <v-icon dark large>mdi-twitter</v-icon>
            </v-btn>
            <v-btn v-if="exhibitor.links.youtube" class="mx-1" color="white" text :href="exhibitor.links.youtube" target="_blank">
              <v-icon dark large>mdi-youtube</v-icon>
            </v-btn>
            <v-btn v-if="exhibitor.links.instagram" class="mx-1" color="white" text :href="exhibitor.links.instagram" target="_blank">
              <v-icon dark large>mdi-instagram</v-icon>
            </v-btn>
            <v-btn v-if="exhibitor.links.website" class="mx-1" color="white" text :href="exhibitor.links.website" target="_blank">
              <v-icon dark large>mdi-link</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-text>
              <ColorCircle color="red" />
              <br style="clear:both" />
              <div v-html="exhibitor.description" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="exhibitor.gallery">
      <v-row>
        <v-col cols="12">
          <v-carousel v-if="exhibitor.gallery.length > 0" :height="300" show-arrows-on-hover hide-delimiter-background>
            <v-carousel-item v-for="(item,i) in exhibitor.gallery" :key="i" contain :src="'https://directus.offenestadt.info/assets/' + item.directus_files_id" />
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ColorCircle from '../components/ColorCircle.vue'

export default {
  name: 'Exhibitor',
  components: {
    ColorCircle
  },
  data: function () {
    return {
      exhibitor: {},
    }
  },
  created() {

    // TODO: enable! if id == number -> get exhibitor by id otherwise by short_name
    let route_filter = null
    if (Number(this.$route.params.id)) route_filter = {id: {eq: this.$route.params.id}}
    else  route_filter = {'short_name': {eq: this.$route.params.id}}

    this.$client.items('exhibitors').readOne(this.$route.params.id, { fields: ['*.*','tags.*.*']})
                .then(data => {
                    this.exhibitor = data
                })
                .catch(error => console.error(error));
  },
  computed:{
    virtualBoothStartTime: function () {
      if(!this.exhibitor.start_time) return null

      return this.exhibitor.start_time.substring(0, this.exhibitor.start_time.length - 3)
    },
    virtualBoothEndTime: function () {
      if(!this.exhibitor.end_time) return null

      return this.exhibitor.end_time.substring(0, this.exhibitor.end_time.length - 3)
    },
    galleryImages: function() {
/*
      if (!this.exhibitor.media || this.exhibitor.media.length == 0) return []

      let content = []

      this.exhibitor.media.forEach(item =>  {
        if (item.directus_files_id.type == 'image/jpeg' ||  item.directus_files_id.type == 'image/png') content.push(item.directus_files_id)
      })

      return content
      */
     return null
    }
  }
}
</script>

<style lang="scss">

  .v-window-item iframe{
      width: 100%;
      height: 100%;
  }
</style>